import { useMemo } from 'react'
import useSWRImmutable from 'swr/immutable'
import { FetchStatus } from 'config/constants/types'
import { Address } from 'wagmi'
import { ChainId } from '@dione/sdk'
import { useUNSContract } from './useContract'

function getUnsAddress() {
  return ''
}

export const useUnsNameForAddress = (address: Address, fetchData = true) => {
  const unsEtherContract = useUNSContract(getUnsAddress(), ChainId.ODYSSEY_TESTNET, undefined)

  const { data: unsName, status } = useSWRImmutable(
    fetchData && address ? ['unsName', address.toLowerCase()] : null,
    async () => {
      const etherDomainName = await unsEtherContract.read.reverseNameOf([address])
      if (!etherDomainName) {
        return {
          name: null,
        }
      }
      return {
        name: etherDomainName,
      }
    },
  )

  return useMemo(() => {
    return { unsName: unsName?.name, isLoading: status !== FetchStatus.Fetched }
  }, [unsName, status])
}
