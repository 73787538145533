import { createReducer } from '@reduxjs/toolkit'
import { DEFAULT_DEADLINE_FROM_NOW } from 'config/constants'
import { updateVersion } from '../global/actions'
import { updateUserDeadline, setSubgraphHealthIndicatorDisplayed } from './actions'

const currentTimestamp = () => Date.now()

export interface UserState {
  // the timestamp of the last updateVersion action
  lastUpdateVersionTimestamp?: number

  // deadline set by user in minutes, used in all txns
  userDeadline: number

  isSubgraphHealthIndicatorDisplayed: boolean
  hideTimestampPhishingWarningBanner: number
}

export const initialState: UserState = {
  userDeadline: DEFAULT_DEADLINE_FROM_NOW,
  isSubgraphHealthIndicatorDisplayed: false,
  hideTimestampPhishingWarningBanner: null,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateVersion, (state) => {
      // slippage is'nt being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard

      // deadline isnt being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard
      if (typeof state.userDeadline !== 'number') {
        state.userDeadline = DEFAULT_DEADLINE_FROM_NOW
      }

      state.lastUpdateVersionTimestamp = currentTimestamp()
    })
    .addCase(updateUserDeadline, (state, action) => {
      state.userDeadline = action.payload.userDeadline
    })
    .addCase(setSubgraphHealthIndicatorDisplayed, (state, { payload }) => {
      state.isSubgraphHealthIndicatorDisplayed = payload
    }),
)
