import memoize from 'lodash/memoize'
import { ContextApi } from '@dione/localization'
import { PageMeta } from './types'
import { ASSET_CDN } from './endpoints'

export const DEFAULT_META: PageMeta = {
  title: 'Dione Protocol Governance',
  description: 'Dione Protocol Governance',
  image: `${ASSET_CDN}/images/og/hero.jpg`,
}

interface PathList {
  paths: { [path: string]: { title: string; basePath?: boolean; description?: string; image?: string } }
  defaultTitleSuffix: string
}

const getPathList = (t: ContextApi['t']): PathList => {
  return {
    paths: {
      '/': { basePath: true, title: t('Voting'), image: `${ASSET_CDN}/images/og/voting.jpg` },
      '/snapshot/proposal': { title: t('Proposals'), image: `${ASSET_CDN}/images/og/voting.jpg` },
      '/snapshot/proposal/create': { title: t('Make a Proposal'), image: `${ASSET_CDN}/images/og/voting.jpg` },
      '/governance/proposal': { title: t('Proposals'), image: `${ASSET_CDN}/images/og/voting.jpg` },
      '/governance/proposal/create': { title: t('Make a Proposal'), image: `${ASSET_CDN}/images/og/voting.jpg` },
    },
    defaultTitleSuffix: t('Dione Protocol Governance'),
  }
}

export const getCustomMeta = memoize(
  (path: string, t: ContextApi['t'], _: string): PageMeta => {
    const pathList = getPathList(t)
    const pathMetadata =
      pathList.paths[path] ??
      pathList.paths[Object.entries(pathList.paths).find(([url, data]) => data.basePath && path.startsWith(url))?.[0]]

    if (pathMetadata) {
      return {
        title: `${pathMetadata.title}`,
        ...(pathMetadata.description && { description: pathMetadata.description }),
        image: pathMetadata.image,
      }
    }
    return null
  },
  (path, t, locale) => `${path}#${locale}`,
)
