import { Abi, Address } from 'viem'
import { erc20ABI, useWalletClient } from 'wagmi'

import { useActiveChainId } from 'hooks/useActiveChainId'

import { useMemo } from 'react'
import { getMulticallAddress, getDioneGovernanceAddress, getGovernanceStrategyAddress } from 'utils/addressHelpers'
import { getContract, getDioneExectutorContract, getSidContract, getUnsContract } from 'utils/contractHelpers'

import { WNATIVE } from '@dione/sdk'
import { multicallABI } from 'config/abi/Multicall'
import { dioneGovernanceABI } from 'config/abi/DioneGovernance'
import { governanceStrategyABI } from 'config/abi/GovernanceStrategy'
import { erc20Bytes32ABI } from 'config/abi/erc20_bytes32'

import { erc721CollectionABI } from 'config/abi/erc721collection'
import { wethABI } from 'config/abi/weth'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = (address: Address) => {
  return useContract(address, erc20ABI)
}

export const useErc721CollectionContract = (collectionAddress: Address) => {
  return useContract(collectionAddress, erc721CollectionABI)
}

// Code below migrated from Exchange useContract.ts

// returns null on errors
export function useContract<TAbi extends Abi>(
  addressOrAddressMap?: Address | { [chainId: number]: Address },
  abi?: TAbi,
) {
  const { chainId } = useActiveChainId()
  const { data: walletClient } = useWalletClient()

  return useMemo(() => {
    if (!addressOrAddressMap || !abi || !chainId) return null
    let address: Address | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract({
        abi,
        address,
        chainId,
        signer: walletClient,
      })
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, abi, chainId, walletClient])
}

export function useTokenContract(tokenAddress?: Address) {
  return useContract(tokenAddress, erc20ABI)
}

export function useWNativeContract() {
  const { chainId } = useActiveChainId()
  return useContract(chainId ? WNATIVE[chainId]?.address : undefined, wethABI)
}

export function useBytes32TokenContract(tokenAddress?: Address) {
  return useContract(tokenAddress, erc20Bytes32ABI)
}

export function useMulticallContract() {
  const { chainId } = useActiveChainId()

  // @ts-ignore
  return useContract(getMulticallAddress(chainId), multicallABI)
}

export const useSIDContract = (address, chainId) => {
  return useMemo(() => getSidContract(address, chainId), [address, chainId])
}

export const useUNSContract = (address, chainId, provider) => {
  return useMemo(() => getUnsContract(address, chainId, provider), [chainId, address, provider])
}

export const useDioneGovernanceContract = () => {
  const { chainId } = useActiveChainId()

  // @ts-ignore
  return useContract(getDioneGovernanceAddress(chainId), dioneGovernanceABI)
}

export const useDioneExecutorContract = (address) => {
  const { chainId } = useActiveChainId()

  return useMemo(() => getDioneExectutorContract(address, chainId), [chainId, address])
}

export const useGovernanceStrategyContract = () => {
  const { chainId } = useActiveChainId()

  // @ts-ignore
  return useContract(getGovernanceStrategyAddress(chainId), governanceStrategyABI)
}
