/* eslint-disable react/no-array-index-key */
import React, { Children, isValidElement, ReactNode } from "react";
import styled from "styled-components";

const Shape = styled.div`
  position: absolute;
  z-index: 19;
  pointer-events: none !important;
`;

const TopShape = styled(Shape)`
  width: 425px;
  height: 619px;
  transform: rotate(-13deg);
  top: -400px;
  left: -250px;
  opacity: 0.8;
  background-color: ${({ theme }) => theme.colors.secondary};
  filter: blur(180px);
`;

const RightShape = styled(Shape)`
  width: 361.293px;
  height: 525.798px;
  opacity: 0.3;
  filter: blur(125px);
  transform: rotate(-75deg);
  background-color: ${({ theme }) => theme.colors.bgBlue};
  top: 120px;
  left: 55%;
`;

const BgShapes: React.FC = () => {
  return (
    <>
      <TopShape />
      <RightShape />
    </>
  );
};

export default BgShapes;
