import { ChainId, Native, NativeCurrency } from '@dione/sdk'
import { useMemo } from 'react'
import { useActiveChainId } from './useActiveChainId'

export default function useNativeCurrency(): NativeCurrency {
  const { chainId } = useActiveChainId()
  return useMemo(() => {
    try {
      return Native.onChain(chainId || ChainId.ODYSSEY_TESTNET)
    } catch (e) {
      return Native.onChain(ChainId.ODYSSEY_TESTNET)
    }
  }, [chainId])
}
