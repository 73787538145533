import { scales, variants } from "./types";

export const scaleVariants = {
  [scales.MD]: {
    height: "48px",
    padding: "0 20px",
  },
  [scales.SM]: {
    height: "32px",
    padding: "0 14px",
  },
  [scales.XS]: {
    height: "20px",
    fontSize: "12px",
    padding: "0 8px",
  },
};

export const styleVariants = {
  [variants.PRIMARY]: {
    backgroundColor: "primary",
    color: "invertedContrast",
  },
  [variants.SECONDARY]: {
    backgroundColor: "transparent",
    border: "2px solid",
    borderColor: "primary",
    boxShadow: "none",
    color: "primary",
    ":disabled": {
      backgroundColor: "transparent",
    },
  },
  [variants.TERTIARY]: {
    backgroundColor: "tertiary",
    boxShadow: "none",
    color: "primary",
  },
  [variants.SUBTLE]: {
    backgroundColor: "textSubtle",
    color: "backgroundAlt",
  },
  [variants.DANGER]: {
    backgroundColor: "failure",
    color: "white",
  },
  [variants.SUCCESS]: {
    backgroundColor: "success",
    color: "white",
  },
  [variants.TEXT]: {
    backgroundColor: "transparent",
    color: "primary",
    boxShadow: "none",
  },
  [variants.LIGHT]: {
    backgroundColor: "input",
    color: "textSubtle",
    boxShadow: "none",
  },
  [variants.BUBBLEGUM]: {
    background: "linear-gradient(119deg, #8239FF 8.65%, #E18DFF 41.44%, #FF94D4 68.05%)",
    color: "text",
    boxShadow: "none",
  },
  [variants.ICONIC]: {
    backgroundColor: "backgroundAlt10",
    color: "text",
    paddingRight: "4px",
    paddingTop: "4px",
    paddingBottom: "4px",
    boxShadow: "0px 0px 50px 0px rgba(255, 255, 255, 0.03) inset",
    backdropFilter: "blur(7px)",
    justifyContent: 'space-between'
  },
};
