import { useMemo } from 'react'
import { useVotingStatus } from './useVotingStatus'

export const useMenuItemsStatus = (): Record<string, string> => {
  const votingStatus = useVotingStatus()

  return useMemo(() => {
    return {
      ...(votingStatus && {
        '/voting': votingStatus,
      })
    }
  }, [votingStatus])
}
