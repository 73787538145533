import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M14.125 5.875L5.875 14.125" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" fill="none"/>
      <path d="M8.33203 5.83337L14.1237 5.87421L14.1654 11.6667" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" fill="none"/>
    </Svg>
  );
};

export default Icon;
