import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | Dione Protocol Governance',
  defaultTitle: 'Dione Protocol Governance',
  description:
    'Setting the foundation for a new era in eco-friendly and sustainable blockchain technologies.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@DioneProtocol_',
    site: '@DioneProtocol_',
  },
  openGraph: {
    title: 'Dione Protocol - Powering the crypto revolution with clean energy',
    description:
      'Setting the foundation for a new era in eco-friendly and sustainable blockchain technologies.',
    images: [{ url: 'https://www.dioneprotocol.com/img/tcard.png' }],
  },
}
