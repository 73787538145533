import { useMemo } from 'react'
import useSWRImmutable from 'swr/immutable'
import { namehash } from 'viem'
import { getSidResolverContract } from 'utils/contractHelpers'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { FetchStatus } from 'config/constants/types'
import { useSIDContract } from './useContract'

function getSidAddress() {
  return ''
}

export const useSidNameForAddress = (address: string, fetchData = true) => {
  const { chainId } = useActiveChainId()
  const sidContract = useSIDContract(getSidAddress(), chainId)

  const { data: sidName, status } = useSWRImmutable(
    fetchData && address ? ['sidName', chainId, address.toLowerCase()] : null,
    async () => {
      const reverseNode = `${address.toLowerCase().slice(2)}.addr.reverse`
      const reverseNameHash = namehash(reverseNode)
      const resolverAddress = await sidContract.read.resolver([reverseNameHash])
      if (parseInt(resolverAddress, 16) === 0) {
        return {
          name: null,
        }
      }
      const resolverContract = getSidResolverContract(resolverAddress)
      const resolvedName = await resolverContract.read.name([reverseNameHash])
      return {
        name: resolvedName,
      }
    },
  )

  return useMemo(() => {
    return { sidName: sidName?.name, isLoading: status !== FetchStatus.Fetched }
  }, [sidName, status])
}
