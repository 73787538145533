import styled from "styled-components";
import { darkColors } from "../../theme/colors";
import { Flex } from "../Box";
import { Link } from "../Link";

export const StyledFooter = styled(Flex)`
  background: ${darkColors.background};
  padding: 32px 0;
  min-height: 147px;
  border-top: 1px solid ${({ theme }) => theme.colors.backgroundAlt2};
  flex-direction: column;
  align-items: stretch;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 32px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    padding: 60px 68px;
  }
`;

export const StyledList = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 24px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  order: 1;
  margin-bottom: 40px;


  ${({ theme }) => theme.mediaQueries.lg} {
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 0;
    order: 3;
    
  }
`;

export const StyledListItem = styled.li`
  font-size: 12px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 500;
  line-height: 1.5;
`;

export const StyledLink = styled(Link)`
  font-size: 12px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 500;
  line-height: 1.5;
`;

export const StyledSeparator = styled.span`
  font-size: 18px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.backgroundAlt10};
  font-weight: 300;
  line-height: 1;
  display: none;


  ${({ theme }) => theme.mediaQueries.lg} {
    display: inline-block;
  }
`;

export const StyledToolsContainer = styled(Flex)`
  ${({ theme }) => theme.mediaQueries.sm} {
    border-top-width: 0;
    border-bottom-width: 0;
    padding: 0 0;
  }
`;

export const StyledText = styled.span`
  font-size: 12px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 500;
  line-height: 1.5;
`;
