import styled from "styled-components";
import { PolymorphicComponent } from "../../util/polymorphic";
import { variants } from "../Button/types";
import { ButtonMenuItemProps } from "./types";


const InactiveButton = styled.button<{ isActive?: boolean; valueID?: string }>`
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  border-radius: 500px;
  display: flex;
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.backgroundAlt10 : 'transparent')};
  color: ${({ isActive, theme }) => (isActive ? theme.colors.text : theme.colors.textSubtle)};
  box-shadow: ${({ isActive, theme }) => (isActive ? theme.shadows.card : 'none')};
  border: none;
  padding: 4px 8px;
  line-height: 20px;
  height: 32px;
`;

const ButtonMenuItem: PolymorphicComponent<ButtonMenuItemProps, "button"> = ({
  isActive = false,
  variant = variants.PRIMARY,
  as,
  ...props
}: ButtonMenuItemProps) => {
  if (!isActive) {
    return <InactiveButton forwardedAs={as} variant={variant} {...props} />;
  }

  return <InactiveButton isActive as={as} variant={variant} {...props} />;
};

export default ButtonMenuItem;
