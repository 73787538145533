import { ContextApi } from "@dione/localization";
import { FooterLinkType } from "../../../components/Footer/types";

export const footerLinks: (t: ContextApi["t"]) => FooterLinkType[] = (t) => [
  {
    label: t("Footer"),
    items: [
      {
        label: t("Audit Report"),
        href: "https://docs.dioneprotocol.com/governance/audit-report",
      },
      {
        label: t("Terms of Service"),
        href: "https://docs.dioneprotocol.com/terms-of-service",
      },
      {
        label: t("Contact Us"),
        href: "https://dioneprotocol.com/contact",
      },
    ],
  },
];
