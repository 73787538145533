import { useMemo } from 'react'
import styled from 'styled-components'
import { Text, Flex, Box, CloseIcon, IconButton, useMatchBreakpoints } from '@dione/uikit'
import { useTranslation } from '@dione/localization'
import { usePhishingBanner } from '@dione/utils/user'

const Container = styled(Flex)`
  overflow: hidden;
  height: 100%;
  flex-direction: column;
  align-items: stretch;
  background: ${({ theme }) => theme.colors.background};
`

const InnerContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 24px;
`

const Overlay = styled(Flex)`
  height: 100%;
  padding: 6px;
  background: ${({ theme }) => theme.colors.backgroundAlt2};
  align-items: center;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 0;
  }
`

const domain = 'https://governance.dioneprotocol.com'

const PhishingWarningBanner: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation()
  const [, hideBanner] = usePhishingBanner()
  const { isMobile, isMd } = useMatchBreakpoints()
  const warningTextAsParts = useMemo(() => {
    const warningText = t("please make sure you're visiting %domain% - check the URL carefully.", { domain })
    return warningText.split(/(https:\/\/governance\.dioneprotocol\.com)/g)
  }, [t])
  const warningTextComponent = (
    <>
      <Text as="span" color="primaryBright" bold textTransform="uppercase" fontSize="13px">
        {t('Phishing warning: ')}
      </Text>
      {warningTextAsParts.map((text, i) => (
        <Text
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          as="span"
          bold={text === domain}
          color={text === domain ? '#FFFFFF' : '#BDC2C4'}
          fontSize="13px"
        >
          {text}
        </Text>
      ))}
    </>
  )
  return (
    <Container className="warning-banner">
      <Overlay>
        {isMobile || isMd ? (
          <>
            <Box>{warningTextComponent}</Box>
            <IconButton onClick={hideBanner} variant="text">
              <CloseIcon color="text" />
            </IconButton>
          </>
        ) : (
          <>
            <InnerContainer>
              <Box>{warningTextComponent}</Box>
            </InnerContainer>
            <IconButton onClick={hideBanner} variant="text">
              <CloseIcon color="text" />
            </IconButton>
          </>
        )}
      </Overlay>
    </Container>
  )
}

export default PhishingWarningBanner
