import { ChainId } from '@dione/sdk'
import addresses from 'config/constants/contracts'

export interface Addresses {
  [chainId: number]: `0x${string}`
}

export const getAddressFromMap = (address: Addresses, chainId?: number): `0x${string}` => {
  return address[chainId] ? address[chainId] : address[ChainId.ODYSSEY_TESTNET]
}

export const getAddressFromMapNoFallback = (address: Addresses, chainId?: number): `0x${string}` | null => {
  return address[chainId]
}

export const getMulticallAddress = (chainId?: number) => {
  return getAddressFromMap(addresses.multiCall, chainId)
}

export const getDioneGovernanceAddress = (chainId?: number) => {
  return getAddressFromMap(addresses.dioneGovernance, chainId)
}

export const getGovernanceStrategyAddress = (chainId?: number) => {
  return getAddressFromMap(addresses.governanceStrategy, chainId)
}
