import { ChainId } from '@dione/sdk'

export const SERVER_NODES = {
  [ChainId.ODYSSEY_TESTNET]: ['https://testnode2.dioneprotocol.com/ext/bc/C/rpc'],
  [ChainId.ODYSSEY]: [],
} satisfies Record<ChainId, readonly string[]>

export const PUBLIC_NODES = {
  [ChainId.ODYSSEY_TESTNET]: ['https://testnode2.dioneprotocol.com/ext/bc/C/rpc'],
  [ChainId.ODYSSEY]: [],
} satisfies Record<ChainId, readonly string[]>
