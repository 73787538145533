export default {
  multiCall: {
    13: '0x8B6Aad85396B513564476ed8B8F85A17d543bF9D',
  },
  dioneGovernance: {
    13: '0x2CD90e4fB91a3080069B69dFccf9d19CCF023235',
  },
  governanceStrategy: {
    13: '0x781EE6a541970E166271a83620BC33CB8b38F56F',
  },
} as const
