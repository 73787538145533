import React from "react";
import styled from "styled-components";
import useMatchBreakpoints from "../../contexts/MatchBreakpoints/useMatchBreakpoints";
import { Text } from "../Text";
import { Box, Flex } from "../Box";
import {
  StyledFooter,
  StyledList,
  StyledListItem,
  StyledText,
  StyledLink,
  StyledSeparator,
  StyledToolsContainer,
} from "./styles";

import { LogoIcon } from "../Svg";
import { FooterProps } from "./types";

const LogoBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${({ theme }) => theme.colors.text};
  ${({ theme }) => theme.mediaQueries.sm} {
    margin-right: 30px;
  }
`;

const MenuItem: React.FC<React.PropsWithChildren<FooterProps>> = ({ items, ...props }) => {
  const { isDesktop } = useMatchBreakpoints();

  return (
    <StyledFooter {...props}>
      <StyledToolsContainer
        order={[1, null, 3]}
        flexDirection={isDesktop ? "row" : "column"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex
          alignItems="center"
          flexDirection={isDesktop ? "row" : "column"}
          justifyContent={isDesktop ? "flex-start" : "space-between"}
          mb={isDesktop ? "0" : "24px"}
        >
          <LogoBox mb={isDesktop ? "0" : "16px"}>
            <LogoIcon width="28px" />
            <Text fontSize="12px" fontWeight={500} color="text">
              Powered by Dione Protocol
            </Text>
          </LogoBox>
        </Flex>
        <Text fontSize="12px" fontWeight={500} color="text" style={{ order: 2}} textAlign="center" px={["16px", null, null, "0"]}>
          &copy; Dione Protocol Copyright 2023. All rights reserved.
        </Text>
        {items?.map((item) => (
          <StyledList key={item.label}>
            {item.items?.map(({ label, href }, _idx) => (
              <>
                <StyledListItem key={label}>
                  {href ? (
                    <StyledLink
                      data-theme="dark"
                      href={href}
                      target="_blank"
                      rel="noreferrer noopener"
                      color="text"
                      bold={false}
                    >
                      {label}
                    </StyledLink>
                  ) : (
                    <StyledText>{label}</StyledText>
                  )}
                </StyledListItem>
                {_idx !== item.items?.length - 1 && <StyledSeparator>|</StyledSeparator>}
              </>
            ))}
          </StyledList>
        ))}
      </StyledToolsContainer>
    </StyledFooter>
  );
};

export default MenuItem;
