import {
  Box,
  Button,
  InjectedModalProps,
  Message,
  Text,
  CopyAddress,
  FlexGap,
} from '@dione/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@dione/localization'
import useAuth from 'hooks/useAuth'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { useDomainNameForAddress } from 'hooks/useDomain'

interface WalletInfoProps {
  hasLowNativeBalance: boolean
  switchView: (newIndex: number) => void
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowNativeBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const { domainName } = useDomainNameForAddress(account)
  const native = useNativeCurrency()
  const { logout } = useAuth()

  const handleLogout = () => {
    onDismiss?.()
    logout()
  }

  return (
    <>
      <Text color="textSubtle" fontSize="12px" textTransform="uppercase" fontWeight="600" mb="8px">
        {t('Your Address')}
      </Text>
      <FlexGap flexDirection="column" mb="16px" gap="6px">
        <CopyAddress tooltipMessage={t('Copied')} account={account} />
        {domainName ? <Text fontSize="12px" fontWeight="500" textAlign="right" color="textSubtle">{domainName}</Text> : null}
      </FlexGap>
      {hasLowNativeBalance && (
        <Message variant="warning" mb="16px">
          <Box>
            <Text fontWeight="600" fontSize="12px" style={{ opacity: 0.8 }}>
              {t('%currency% Balance Low', {
                currency: native.symbol,
              })}
            </Text>
          </Box>
        </Message>
      )}
      <Button variant="iconic" width="100%" minHeight={48} onClick={handleLogout}>
        {t('Disconnect Wallet')}
      </Button>
    </>
  )
}

export default WalletInfo
