import styled from "styled-components";
import React from "react";

import { InputSearchProps, scales } from "./types";
import { Flex } from "../Box";

const getLabelHeight = ({ scale }: InputSearchProps) => {
  switch (scale) {
    case scales.SM:
      return "32px";
    case scales.LG:
      return "56px";
    case scales.MD:
    default:
      return "48px";
  }
};

const Label = styled.label<InputSearchProps>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 16px;
  height: ${getLabelHeight};
  max-width: ${({ maxWidth }) => maxWidth || "230px"};
  box-shadow: ${({ theme }) => theme.shadows.input};
  background-color: ${({ theme }) => theme.colors.backgroundAlt2};
  border: 1px solid transparent;
  border-radius: 600px;
  cursor: text;
  transition: all 0.2s linear;

  &:focus-within {
    border: 1px solid ${({ theme }) => theme.colors.inputSecondary};
  }
`;

const SearchInputInner = styled.input<InputSearchProps>`
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  padding: 0;
  background: none;
  outline: none;
  border: none;
  color: color(--text);

  &::placeholder {
    color: color(--secendary);
  }
`;

const IconContainer = styled(Flex)`
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 20px;
  height: 20px;

  & svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const SearchInput: React.FC<InputSearchProps> = ({ icon, className, ...props }) => {
  return (
    <Label className={className || ""} {...props}>
      {icon ? <IconContainer>{icon}</IconContainer> : null}
      <SearchInputInner {...props} />
    </Label>
  );
};

SearchInput.defaultProps = {
  placeholder: "Search...",
  maxWidth: "230px",
  center: true,
  scale: scales.MD,
};

export default SearchInput;
